import React, { useRef } from "react";
import ReactDom from "react-dom";
import "../styling/Feed.css";
import "../styling/Popup.css";

export const Popup = ({ openPopup, item}) => {
  const popupArea = useRef();
  const closePopup = (e) => {
    if (e.target === popupArea.current) {
      openPopup(false);
    }
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

    return ReactDom.createPortal(
        <div className="popup" ref={popupArea} onClick={closePopup}>
            <div className="popup-item"> 
                <span className="feed-item-header">{item.head}</span>
                <span className="feed-item-desc">{
                  isValidURL(item.desc) ? <a href={item.desc} target="_blank" rel="noreferrer">{item.desc}</a> : item.desc
                }</span>
                <div className="feed-footer">
                    <span className="feed-item-date">{item.footer}</span>
                </div>
            </div>
        </div>,
      document.getElementById("portal")
    );
};
